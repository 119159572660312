import baseState from '../store/baseState';
import {
  GET_OFFER_LIST, GET_OFFER_BY_ID, CREATE_OFFER
} from '../actions/action';

export default (state = baseState.offerData, { payload, type, error }) => {
  switch (type) {

    case GET_OFFER_LIST.REQUEST:
      return {
        ...state,
      };

    case GET_OFFER_LIST.SUCCESS:
      return {
        ...state,
        offerList: payload.data.data
      };

      case GET_OFFER_BY_ID.REQUEST:
        return {
          ...state,
        };
  
      case GET_OFFER_BY_ID.SUCCESS:
        return {
          ...state,
          offerDetails: payload.data.data
        };

      case CREATE_OFFER.REQUEST:
        return {
          ...state,
        };
  
      case CREATE_OFFER.SUCCESS:
        return {
          ...state,
          offerResponse: payload.data.data
        };
    default:
      return state;
  }
};