export default {
  authData: {
    loginDetails: {},
    authenticated: false,
    error: ''
  },

  productData: {
    categoryList: [],
    cloneCategoryList: [],
    productList: [],
    createdProduct: "",
    updateProduct: {},
    productIngredients: [],
    updatedproductIngredients: {},
    productDetails: {}
  },

  offerData: {
    offerList: [],
    offerResponse: {}
  },

  orderData: {
    orderList: [],
    orderDetails: {},
    orderResponse: {}
  },

  managerData: {
    managerResponse: {},
    managerList: [],
    managerDetails: {},
    profileResponse: {}
  },

  ingredientData: {
    ingredientList: [],
    ingredientReportList:[],
    updateIngredientData: {},
    addedIngredientDetails: {},
    resettedIngredientQty:{}
  },

  purchaseOrderData: {
    purchaseOrderList: [],
    newPO: {},
    poDetails: {},
    poMoveStatus: {},
    poCancelStatus: {},
    updatedPOData: {}
  }
}