import baseState from '../store/baseState';
import {
    PO_LIST, CREATE_PO, GET_PO_BY_ID, MOVE_PO, UPDATE_PO, CANCEL_PO
} from '../actions/action';

export default (state = baseState.purchaseOrderData, { payload, type, error }) => {
    switch (type) {

        case PO_LIST.REQUEST:
            return {
                ...state,
            };

        case PO_LIST.SUCCESS:
            return {
                ...state,
                purchaseOrderList: payload.data.data
            };

        case CREATE_PO.REQUEST:
            return {
                ...state,
            };

        case CREATE_PO.SUCCESS:
            return {
                ...state,
                newPO: payload.data.data
            };

        case GET_PO_BY_ID.REQUEST:
            return {
                ...state,
            };

        case GET_PO_BY_ID.SUCCESS:
            return {
                ...state,
                poDetails: payload.data.data
            };

        case MOVE_PO.REQUEST:
            return {
                ...state,
            };

        case MOVE_PO.SUCCESS:
            return {
                ...state,
                poMoveStatus: payload.data.data
            };
        
            case CANCEL_PO.REQUEST:
            return {
                ...state,
            };

        case CANCEL_PO.SUCCESS:
            return {
                ...state,
                poCancelStatus: payload.data.data
            };

        case UPDATE_PO.REQUEST:
            return {
                ...state,
            };

        case UPDATE_PO.SUCCESS:
            return {
                ...state,
                updatedPOData: payload.data.data
            };



        default:
            return state;
    }
};