import baseState from '../store/baseState';
import {
    GET_MANAGER_LIST,
    CREATE_MANAGER,
    GET_MANAGER_DETAILS_BY_ID,
    CHANGE_PROFILE_PASSWORD
} from '../actions/action';

export default (state = baseState.managerData, { payload, type, error }) => {
    switch (type) {

        case CREATE_MANAGER.REQUEST:
            return {
                ...state,
            };

        case CREATE_MANAGER.SUCCESS:
            return {
                ...state,
                managerResponse: payload.data
            };
        case GET_MANAGER_LIST.REQUEST:
            return {
                ...state,
            };

        case GET_MANAGER_LIST.SUCCESS:
            return {
                ...state,
                managerList: payload.data.data
            };

        case GET_MANAGER_DETAILS_BY_ID.REQUEST:
            return {
                ...state,
            };

        case GET_MANAGER_DETAILS_BY_ID.SUCCESS:
            return {
                ...state,
                managerDetails: payload.data.data
            };

        case CHANGE_PROFILE_PASSWORD.REQUEST:
            return {
                ...state,
            };

        case CHANGE_PROFILE_PASSWORD.SUCCESS:
            return {
                ...state,
                profileResponse: payload.data
            };
        default:
            return state;
    }
};