import baseState from '../store/baseState';
import {
  CATEGORY_LIST, PRODUCT_LIST, CLONE_CATEGORY_LIST, CREATE_PRODUCT, UPDATE_PRODUCT, GET_PRODUCT_INGREDIENTS, GET_PRODUCT_BY_ID, UPDATE_PRODUCT_INGREDIENTS
} from '../actions/action';

export default (state = baseState.productData, { payload, type, error }) => {
  switch (type) {

    case CATEGORY_LIST.REQUEST:
      return {
        ...state,
      };

    case CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        categoryList: payload.data.data
      };

    case PRODUCT_LIST.REQUEST:
      return {
        ...state,
      };

    case PRODUCT_LIST.SUCCESS:
      return {
        ...state,
        productList: payload.data
      };


    case CLONE_CATEGORY_LIST.REQUEST:
      return {
        ...state,
      };

    case CLONE_CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        cloneCategoryList: payload.data.data
      };

    case UPDATE_PRODUCT.REQUEST:
      return {
        ...state,
      };

    case UPDATE_PRODUCT.SUCCESS:
      return {
        ...state,
        updateProduct: payload.data.data
      };

    case GET_PRODUCT_INGREDIENTS.REQUEST:
      return {
        ...state,
      };

    case GET_PRODUCT_INGREDIENTS.SUCCESS:
      return {
        ...state,
        productIngredients: payload.data.data
      };

    case UPDATE_PRODUCT_INGREDIENTS.REQUEST:
      return {
        ...state,
      };

    case UPDATE_PRODUCT_INGREDIENTS.SUCCESS:
      return {
        ...state,
        updatedproductIngredients: payload.data.data
      };

    case GET_PRODUCT_BY_ID.REQUEST:
      return {
        ...state,
      };

    case GET_PRODUCT_BY_ID.SUCCESS:
      return {
        ...state,
        productDetails: payload.data.data
      };
    
      case CREATE_PRODUCT.REQUEST:
      return {
        ...state,
      };

    case CREATE_PRODUCT.SUCCESS:
      return {
        ...state,
        createdProduct: payload.data.data
      };


    default:
      return state;
  }
};