import axios from 'axios';
import {
    createAction,
    GET_MANAGER_LIST,
    CREATE_MANAGER,
    GET_MANAGER_DETAILS_BY_ID,
    CHANGE_PROFILE_PASSWORD
} from './action';
import { getUserApiUrl } from '../config/api';
import { HEADERS } from '../config/appHeaders';


//create manager profile
export const createManager = (data) => {
  return createAction({
    type: CREATE_MANAGER,
    action: () => axios.post(getUserApiUrl()+"/manager", data, { headers: HEADERS.AUTHENTIC() })
  });
}

//get manager list
export const getManagerList = () => {
  return createAction({
    type: GET_MANAGER_LIST,
    action: async () => await axios.get(getUserApiUrl()+"/managers", { data: {}, headers: HEADERS.AUTHENTIC() })
  });
}

//get manager by id
export const getManagerDetailsById = (managerId) => {
  return createAction({
    type: GET_MANAGER_DETAILS_BY_ID,
    action: async () => await axios.get(getUserApiUrl()+"/manager/"+managerId, { data: {}, headers: HEADERS.AUTHENTIC() })
  });
}

//Change profile password
export const changeProfilePassword = (data) => {
  return createAction({
    type: CHANGE_PROFILE_PASSWORD,
    action: () => axios.post(getUserApiUrl()+"/profile", data, { headers: HEADERS.AUTHENTIC() })
  });
}

