import baseState from '../store/baseState';
import {
    INGREDIENT_LIST, UPDATE_INGREDIENT, ADD_INGREDIENT, INGREDIENT_REPORT_LIST, RESET_INGREDIENTS_QTY
} from '../actions/action';

export default (state = baseState.ingredientData, { payload, type, error }) => {
    switch (type) {

        case INGREDIENT_LIST.REQUEST:
            return {
                ...state,
            };

        case INGREDIENT_LIST.SUCCESS:
            return {
                ...state,
                ingredientList: payload.data
            };

        case INGREDIENT_REPORT_LIST.REQUEST:
            return {
                ...state,
            };

        case INGREDIENT_REPORT_LIST.SUCCESS:
            return {
                ...state,
                ingredientReportList: payload.data.data
            };

        case UPDATE_INGREDIENT.REQUEST:
            return {
                ...state,
            };

        case UPDATE_INGREDIENT.SUCCESS:
            return {
                ...state,
                updateIngredientData: payload.data.data
            };

        case ADD_INGREDIENT.REQUEST:
            return {
                ...state,
            };

        case ADD_INGREDIENT.SUCCESS:
            return {
                ...state,
                addedIngredientDetails: payload.data.data
            };

        case RESET_INGREDIENTS_QTY.REQUEST:
            return {
                ...state,
            };

        case RESET_INGREDIENTS_QTY.SUCCESS:
            return {
                ...state,
                resettedIngredientQty: payload.data.data
            };

        default:
            return state;
    }
};