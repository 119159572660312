export const SESSION_KEYS = {
    USER: 'USER',
    EXPIRY: 'EXPIRY'
};

export const BABY_ERP_SCREENS = [{ parent: 'dashboard', child: [{ name: 'dashboard' }] },
{
    parent: 'customer', child: [{ id: 'bal', name: 'customerbalance' },
    { id: 'pay', name: 'payment' },
    { id: 'saleOrder', name: 'salesOrder' },
    { id: 'customerAccount', name: 'customer' },
    { id: 'paymentSchedule', name: 'paymentSchedules' },]
},
{
    parent: 'inventory', child: [{ id: 'search', name: 'inventoryTransfer' },
    { id: 'stock', name: 'stockTransfer' },
    { id: 'stockOrder', name: 'stockOrder' }]
},
{
    parent: 'supplier', child: [{ id: 'supplBal', name: 'supplierBalance' },
    { id: 'supplPay', name: 'supplierPayment' },
    { id: 'purchOrder', name: 'supplierPurchaseOrder' },
    { id: 'suppl', name: 'supplier' },
    { id: 'brand', name: 'brand' },]
},
{
    parent: 'accounts', child: [{ id: 'branch', name: 'branch' },
    { id: 'manager', name: 'manager' },
    { id: 'companyAccount', name: 'company' }]
},
{
    parent: 'masters', child: [    
    { id: 'category', name: 'productCategory' },
    { id: 'prodItem', name: 'productItem' }]
},
{
    parent: 'expenses', child: [{ id: 'head', name: 'heads' },
    { id: 'search', name: 'search' },
    { id: 'expenseCate', name: 'expenseCategory' },
    { id: 'vendors', name: 'vendor' }]
},
{
    parent: 'reports', child: [{ id: 'gstReport', name: 'gstReport' },
    { id: 'profitReport', name: 'profitReport' },
    { id: 'report3', name: '#' }]
},
{
    parent: 'humanResources', child: [{ id: 'e-identification', name: 'electronicNumbers' },
    { id: 'employees', name: 'employees' },
    { id: 'employeeAttendance', name: 'employeeAttendance' },
    { id: 'salary', name: 'salary' },
    { id: 'cash-register', name: 'cash' },
    { id: 'report-data', name: 'reports' },
    { id: 'att-report', name: 'attandanceReport' },
    { id: 'settings', name: 'settings' }]
    
},
{ parent: 'dashboard', child: [{ name: 'dashboard' }] },]