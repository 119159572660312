import React from 'react';
import { Nav, Row, Button, Dropdown, Navbar, Image, Container, Modal, Form, Col, Toast, ToastContainer } from 'react-bootstrap';
import '../../assets/NavBar.css';
import { connect } from 'react-redux';
import { changeProfilePassword } from '../../actions/manager';
import masterlogo from '../../assets/images/mf-logo.png';

class NavBar extends React.Component {

  state = {
    profileModal: false,
    input: {},
    errors: {},
    profileResponse: {},
    profileDetail: {},
    toastMessage: { message: '', condition: false },
    hide: 'fa fa-eye-slash',
    hideNew: 'fa fa-eye-slash',
    hideCurrent: 'fa fa-eye-slash',
    roleManager: "",
    userLogin: ""
  };

  componentDidMount() {
    this.getUserRole();
    this.getUserName();
  }

  getUserRole = () => {
    let role = localStorage.getItem("userRole");
    this.setState({
      roleManager: role
    })
  }

  getUserName = () => {
    let userLogin = localStorage.getItem("userLogin");
    this.setState({
      userLogin
    })
  }

  toggleProfileModal = () => {
    this.setState(prevState => ({
      profileModal: !prevState.profileModal
    }));
  }

  handleChange = (event) => {
    let input = this.state.input;
    const { name, value } = event.target;
    const { profileDetail } = this.state;
    input[event.target.name] = event.target.value;
    this.setState({
      profileDetail: {
        ...profileDetail,
        [name]: value
      },
      input
    })

  }

  createProfilePassword = () => {
    const { profileDetail } = this.state;
    let obj = { data: profileDetail };
    if (this.validate()) {
      this.props.changeProfilePassword(JSON.stringify(obj)).then(response => {
        const { profileResponse } = this.props;
        if (window.navigator.onLine === true) {
          if (profileResponse.successMessage === "SUCCESS") {
            this.setState({
              toastMessage: {
                condition: true,
                message: 'Your password changed Successfully'
              },
              profileModal: false
            })
            setTimeout(() => {
              this.setState({
                toastMessage: {
                  condition: false,
                  message: ''
                }
              })
            }, 2000);
            localStorage.removeItem("USER");
            localStorage.removeItem("userRole");
            window.location.reload();
            //this.props.history.push('/user/login');
            this.forceUpdate();
          }
        } else {
          this.setState({
            toastMessage: {
              condition: true,
              message: 'Check your internet Connection'
            }
          })
          setTimeout(() => {
            this.setState({
              toastMessage: {
                condition: false,
                message: ''
              }
            })
          }, 2000);
        }
      });
    }
  }

  validate = () => {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your name.";
    }

    if (!input["oldPassword"]) {
      isValid = false;
      errors["oldPassword"] = "Please enter your name.";
    }

    if (!input["confirmPassword"]) {
      isValid = false;
      errors["confirmPassword"] = "Please enter your name.";
    }

    if (typeof input["password"] !== "undefined" && typeof input["confirmPassword"] !== "undefined") {
      if (input["password"] !== input["confirmPassword"]) {
        isValid = false;
        errors["password"] = "Passwords don't match.";
        errors["confirmPassword"] = "confirmPassword don't match.";
      }
    }

    this.setState({
      errors: errors
    });

    return isValid;
  }

  hideSwitch = ev => {
    if (this.state.hide === 'fa fa-eye-slash') {
      if (document.getElementById('passwordfield')) {
        document.getElementById('passwordfield').type = 'text';
      }
      this.setState({
        hide: 'fa fa-eye',
      })
    }
    else {
      if (document.getElementById('passwordfield')) {
        document.getElementById('passwordfield').type = 'password';
      }
      this.setState({
        hide: 'fa fa-eye-slash',
      })
    }
  }

  hideSwitchNew = ev => {
    if (this.state.hideNew === 'fa fa-eye-slash') {
      if (document.getElementById('passwordfieldNew')) {
        document.getElementById('passwordfieldNew').type = 'text';
      }
      this.setState({
        hideNew: 'fa fa-eye',
      })
    }
    else {
      if (document.getElementById('passwordfieldNew')) {
        document.getElementById('passwordfieldNew').type = 'password';
      }
      this.setState({
        hideNew: 'fa fa-eye-slash',
      })
    }
  }

  hideSwitchCurrent = ev => {
    if (this.state.hideCurrent === 'fa fa-eye-slash') {
      if (document.getElementById('passwordfieldCurrent')) {
        document.getElementById('passwordfieldCurrent').type = 'text';
      }
      this.setState({
        hideCurrent: 'fa fa-eye',
      })
    }
    else {
      if (document.getElementById('passwordfieldCurrent')) {
        document.getElementById('passwordfieldCurrent').type = 'password';
      }
      this.setState({
        hideCurrent: 'fa fa-eye-slash',
      })
    }
  }

  toggleUserRole = () => {
    localStorage.removeItem("userRole");
    localStorage.removeItem("USER");
  }

  render() {
    const { errors, hide, hideNew, hideCurrent, profileModal, toastMessage, roleManager } = this.state;
    return (
      <>
        <Navbar className='py-0 bg-white mb-1' sticky='top' collapseOnSelect >
          <Container fluid>
            <Navbar.Brand href="#" className='p-0'>
            <Image width="75px" src={masterlogo} alt="" />
          </Navbar.Brand>          
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav>
                {/* <Navbar.Brand href="#" className='p-0'>
            <Image width="75px" src={masterlogo} alt="" />
          </Navbar.Brand> */}
                {roleManager === "ROLE_MANAGER" ? <>
                  <Nav.Link className='pe-0' href="/home">
                    <Button className='rounded-0 shadow-none fw-bold bg-light-blue text-uppercase'>Menu</Button>
                  </Nav.Link>

                  <Nav.Link className='pe-0' href="/orderHistory">
                    <Button className='rounded-0 shadow-none fw-bold bg-light-blue text-uppercase'>Orders</Button>
                  </Nav.Link>

                </>
                  : null}
                {roleManager === "ROLE_ADMIN" ?
                  <>
                     <Nav.Link className='pe-0' href="/user/managers">
                      <Button className='rounded-0 shadow-none fw-bold bg-light-blue text-uppercase'>Manager</Button>
                    </Nav.Link>

                    {/*<Nav.Link className='pe-0' href="/offerhistory">
                      <Button className='rounded-0 shadow-none fw-bold bg-light-blue text-uppercase'>Offer History</Button>
                    </Nav.Link>

                    <Nav.Link className='pe-0' href="/orderHistory">
                      <Button className='rounded-0 shadow-none fw-bold bg-light-blue text-uppercase'>Orders</Button>
                    </Nav.Link> */}
                    <Nav.Link className='pe-0' href="/productManagement">
                      <Button className='rounded-0 shadow-none fw-bold bg-light-blue text-uppercase'>Products</Button>
                    </Nav.Link>
                    <Nav.Link className='pe-0' href="/purchaseorder">
                      <Button className='rounded-0 shadow-none fw-bold bg-light-blue text-uppercase'>PO</Button>
                    </Nav.Link>
                    <Nav.Link className='pe-0' href="/inventory">
                      <Button className='rounded-0 shadow-none fw-bold bg-light-blue text-uppercase'>Inventory</Button>
                    </Nav.Link>
                  </>
                  : null}
                <Dropdown className='p-2 pe-0' align="end" >
                  <Dropdown.Toggle className='rounded-0 shadow-none border-0 bg-light text-dark fw-bold py-2 text-uppercase' id="dropdown-basic">
                    Master Food
                  </Dropdown.Toggle>
                  <Dropdown.Menu id="dropdown-menu-align-end">
                    <Dropdown.Item onClick={this.toggleProfileModal}>Profile</Dropdown.Item>
                    <Dropdown.Item href="/user/login" onClick={this.toggleUserRole}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <NavDropdown title=" Master Food" align="end" id="nav-dropdown">
                <NavDropdown.Item href="/">Logout</NavDropdown.Item>
              </NavDropdown> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {profileModal === true ?
          <Row>
            <Modal size="md" show={profileModal} onHide={this.toggleProfileModal}>
              <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5'>Create New Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={12}>
                    <Row className='pt-2'>
                      <Col md>
                        <Form.Floating className="mb-3">
                          <Form.Control type="text" autoComplete='off' name="username" id="floatingInputCustom" value={this.state.userLogin} placeholder="User Name" onChange={this.handleChange} />
                          <Form.Label htmlFor="floatingInputCustom" className="login-label">Name</Form.Label>
                        </Form.Floating>
                        {!errors.oldPassword ?
                          <Form.Floating className="mb-3">
                            <Form.Control type="password" autoComplete='off' id="passwordfield" name="oldPassword" placeholder=" Old Password" onChange={this.handleChange} />
                            <span className="input-group-text border-0 bg-transparent view-password" id="basic-addon1"><i onClick={this.hideSwitch} className={hide} aria-hidden="true"></i></span>
                            <Form.Label htmlFor="floatingInputCustom" className="login-label">Old Password</Form.Label>
                          </Form.Floating> :
                          <Form.Floating className="mb-3">
                            <Form.Control type="password" autoComplete='off' id="passwordfield" isInvalid name="oldPassword" placeholder=" Old Password" onChange={this.handleChange} />
                            <span className="input-group-text border-0 bg-transparent view-password" id="basic-addon1"><i onClick={this.hideSwitch} className={hide} aria-hidden="true"></i></span>
                            <Form.Label htmlFor="floatingInputCustom" className="login-label">Old Password</Form.Label>
                          </Form.Floating>}
                        {!errors.password ?
                          <Form.Floating className="mb-3">
                            <Form.Control type="password" autoComplete='off' id="passwordfieldNew" name="password" placeholder="New Password" onChange={this.handleChange} />
                            <span className="input-group-text border-0 bg-transparent view-password" id="basic-addon1"><i onClick={this.hideSwitchNew} className={hideNew} aria-hidden="true"></i></span>
                            <Form.Label htmlFor="floatingInputCustom" className="login-label">New Password</Form.Label>
                          </Form.Floating> :
                          <Form.Floating className="mb-3">
                            <Form.Control type="password" autoComplete='off' id="passwordfieldNew" isInvalid name="password" placeholder="New Password" onChange={this.handleChange} />
                            <span className="input-group-text border-0 bg-transparent view-password" id="basic-addon1"><i onClick={this.hideSwitchNew} className={hideNew} aria-hidden="true"></i></span>
                            <Form.Label htmlFor="floatingInputCustom" className="login-label">New Password</Form.Label>
                          </Form.Floating>}
                        {!errors.confirmPassword ?
                          <Form.Floating className="mb-3">
                            <Form.Control type="password" autoComplete='off' id="passwordfieldCurrent" name="confirmPassword" placeholder="Current Password" onChange={this.handleChange} />
                            <span className="input-group-text border-0 bg-transparent view-password" id="basic-addon1"><i onClick={this.hideSwitchCurrent} className={hideCurrent} aria-hidden="true"></i></span>
                            <Form.Label htmlFor="floatingInputCustom" className="login-label">Current Password</Form.Label>
                          </Form.Floating> :
                          <Form.Floating className="mb-3">
                            <Form.Control type="password" autoComplete='off' id="passwordfieldCurrent" isInvalid name="confirmPassword" placeholder="Current Password" onChange={this.handleChange} />
                            <span className="input-group-text border-0 bg-transparent view-password" id="basic-addon1"><i onClick={this.hideSwitchCurrent} className={hideCurrent} aria-hidden="true"></i></span>
                            <Form.Label htmlFor="floatingInputCustom" className="login-label">Current Password</Form.Label>
                          </Form.Floating>}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className='border-0 pt-0'>
                <Row className='g-0 w-100 justify-content-end'>
                  <Col md={3}>
                    <Button className='rounded-0 shadow-none w-100 py-2   fw-bold mb-2 bg-light-red' variant="danger" onClick={this.createProfilePassword}>
                      Update
                    </Button>
                  </Col>
                </Row>
              </Modal.Footer>
            </Modal>
          </Row> : null}

        {toastMessage.condition !== false ?
          <>
            <ToastContainer className="toast-manager px-1">
              <Toast className='toast-box-manager arrow-bottom-right p-1'>
                <Toast.Body>
                  <Row>
                    <Col md={12} className='text-center pt-2'>
                      <h6>{toastMessage.message}</h6>
                    </Col>
                  </Row>
                </Toast.Body>
              </Toast>
            </ToastContainer>
          </>
          : null}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    profileResponse: state.managerData.profileResponse
  };
};

const mapDispatchToProps = {
  changeProfilePassword
};
export default connect(mapStateToProps, mapDispatchToProps)(NavBar); 
