import baseState from '../store/baseState';
import {
    CREATE_UPDATE_ORDER,
    ORDER_LIST,
    GET_ORDER_DETAILS_BY_ID
} from '../actions/action';

export default (state = baseState.orderData, { payload, type, error }) => {
    switch (type) {

        case CREATE_UPDATE_ORDER.REQUEST:
            return {
                ...state,
            };

        case CREATE_UPDATE_ORDER.SUCCESS:
            return {
                ...state,
                orderResponse: payload.data.data
            };
        case ORDER_LIST.REQUEST:
            return {
                ...state,
            };

        case ORDER_LIST.SUCCESS:
            return {
                ...state,
                orderList: payload.data.data
            };

        case GET_ORDER_DETAILS_BY_ID.REQUEST:
            return {
                ...state,
            };

        case GET_ORDER_DETAILS_BY_ID.SUCCESS:
            return {
                ...state,
                orderDetails: payload.data.data
            };
        default:
            return state;
    }
};